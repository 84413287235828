import React from 'react';

import SEO from "../components/seo";
import Layout from "../components/layout";
import ResearchSection from "../components/ResearchSection";
import MobileSection from '../containers/mobile/ResearchProduct'

const ResearchPage = (props: any) => {
  const { product, section } = props.pageContext;

  return (
    <Layout>
      <SEO title="Research" />
      <ResearchSection product={product} section={section} />
      <MobileSection product={product} />
    </Layout>
  );
};


export default ResearchPage;
