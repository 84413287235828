import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import IResearchProduct from '../../interfaces/ResearchProduct';
import Colors from '../../colors';
import ArrowLink from '../../images/arrow-link.svg';

const query = graphql`
  query ResearchSection($name: String) {
    sectionsYaml(name: { eq: $name }) {
      name
    }
  }
`;

const StyledLayout = styled.div`
  padding: 32px 10%;
  display: none;

  h2 {
    font-size: 1.125rem;
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: ${Colors.primary};
    display: flex;
  }

  .product__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 56px;
  }

  .product__body {
    font-size: 1.125rem;
    font-weight: 300;

    svg {
      margin-right: 24px;
    }
  }

  .product__description {
    margin-bottom: 56px;
  }

  .product__arrow-link {
    margin-left: 16px;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Shield = () => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none">
      <path d="M6.50701 2.385C5.07839 2.77872 3.65948 3.20685 2.25151 3.669C2.12294 3.71065 2.00866 3.78756 1.92167 3.89099C1.83469 3.99442 1.7785 4.1202 1.75951 4.254C0.928508 10.4895 2.84851 15.039 5.13901 18.036C6.10878 19.3176 7.26512 20.4466 8.56951 21.3855C9.08851 21.7515 9.54751 22.0155 9.90901 22.185C10.089 22.2705 10.236 22.3275 10.3485 22.362C10.3977 22.3793 10.4484 22.3918 10.5 22.3995C10.551 22.3912 10.6011 22.3787 10.65 22.362C10.764 22.3275 10.911 22.2705 11.091 22.185C11.451 22.0155 11.9115 21.75 12.4305 21.3855C13.7349 20.4466 14.8912 19.3176 15.861 18.036C18.1515 15.0405 20.0715 10.4895 19.2405 4.254C19.2217 4.12014 19.1656 3.99427 19.0786 3.89082C18.9915 3.78736 18.8772 3.71049 18.7485 3.669C17.772 3.3495 16.1235 2.829 14.493 2.3865C12.828 1.935 11.2965 1.6005 10.5 1.6005C9.70501 1.6005 8.17201 1.935 6.50701 2.3865V2.385ZM6.10801 0.84C7.73551 0.3975 9.46501 0 10.5 0C11.535 0 13.2645 0.3975 14.892 0.84C16.557 1.29 18.2355 1.8225 19.2225 2.145C19.6352 2.28128 20.001 2.5312 20.278 2.86605C20.555 3.20091 20.732 3.60711 20.7885 4.038C21.6825 10.7535 19.608 15.7305 17.091 19.023C16.0237 20.4315 14.751 21.6718 13.3155 22.7025C12.8191 23.0592 12.2932 23.3728 11.7435 23.64C11.3235 23.838 10.872 24 10.5 24C10.128 24 9.67801 23.838 9.25651 23.64C8.70678 23.3728 8.18087 23.0592 7.68451 22.7025C6.24905 21.6717 4.97638 20.4314 3.90901 19.023C1.39201 15.7305 -0.682492 10.7535 0.211508 4.038C0.268039 3.60711 0.444973 3.20091 0.721983 2.86605C0.998994 2.5312 1.36485 2.28128 1.77751 2.145C3.2103 1.67521 4.65422 1.24009 6.10801 0.84Z" fill="white"/>
      <path d="M12.75 9.7498C12.7502 10.2153 12.6061 10.6694 12.3375 11.0495C12.0688 11.4297 11.6889 11.7171 11.25 11.8723L11.8275 14.8573C11.8485 14.9659 11.8453 15.0777 11.818 15.1849C11.7907 15.2921 11.74 15.3919 11.6696 15.4772C11.5992 15.5625 11.5109 15.6311 11.4108 15.6783C11.3108 15.7254 11.2016 15.7498 11.091 15.7498H9.909C9.79854 15.7496 9.68949 15.725 9.58964 15.6778C9.48979 15.6305 9.40161 15.5618 9.33139 15.4766C9.26117 15.3913 9.21065 15.2916 9.18345 15.1845C9.15624 15.0775 9.15301 14.9657 9.174 14.8573L9.75 11.8723C9.36114 11.7348 9.01755 11.4931 8.75678 11.1735C8.49601 10.854 8.32809 10.4689 8.27138 10.0604C8.21466 9.65186 8.27132 9.2356 8.43518 8.8571C8.59904 8.4786 8.86378 8.15241 9.20048 7.9142C9.53718 7.67599 9.93288 7.5349 10.3443 7.50637C10.7558 7.47783 11.1672 7.56295 11.5335 7.7524C11.8999 7.94186 12.2071 8.22838 12.4217 8.58064C12.6362 8.9329 12.7498 9.33735 12.75 9.7498Z" fill="white"/>
    </svg>

  )
}

interface Props {
  product: IResearchProduct;
}

const ResearchProduct = ({ product }: Props) => {
  return (
    <StyledLayout className='product'>
      <div className='product__title'>
        <h2>{ product.name }</h2>
      </div>
      <div className='product__body'>
        <p className='product__description'>{ product.description }</p>
        { product.link
            ? <span>
                <a href={product.link}>{ product.name }</a>
                <img className='product__arrow-link' src={ ArrowLink } />
              </span>
            : <div>
                <a href={`mailto:contact@after-math.es?subject=Access for ${product.name}`}>
                  <Shield />
                  <span>
                      Request <strong>access</strong>
                  </span>
                  <img className='product__arrow-link' src={ ArrowLink } />
                </a>
              </div>
        }
      </div>
    </StyledLayout>
  );
};

export default ResearchProduct;
